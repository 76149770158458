import "vant/lib/index.css"; // vant样式
import "@/style/index.scss"; // 全局样式
import "lib-flexible"; // rem适配

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vant from "vant";
import store from "./store";
import touch from "vue-directive-touch";

import "video.js/dist/video-js.css"; // 引入video.js的css
import hls from "videojs-contrib-hls"; // 播放hls流需要的插件
Vue.use(hls);

// 手机调试
import VConsole from "vconsole";
if (["development"].includes(process.env.NODE_ENV)) {
  const vConsole = new VConsole();
  Vue.use(vConsole);
}

Vue.use(Vant);
Vue.use(touch);

// 路由守备
router.beforeEach((to, from, next) => {
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
