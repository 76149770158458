import axios from "axios";
import store from "@/store";
import { Toast } from "vant";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
});

// request 拦截
service.interceptors.request.use(
  (config) => {
    // 可以在请求头设置token
    // 可以处理请求体格式
    if (store.getters.token) {
      // config.headers['token'] = getToken()
      // config.headers['parkId'] = getParkId()
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response 拦截
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // 可以处理各种状态码
    return Promise.resolve(res);
  },
  (error) => {
    Toast.fail("服务异常，请稍后再试!");
    return Promise.reject(error);
    // if (error.response.data.code === "00001") {
    //   window.location.reload();
    // } else {
    //   Toast.fail("服务异常，请稍后再试!");
    //   return Promise.reject(error);
    // }
  }
);

export default service;
