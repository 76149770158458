import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    // 默认进入页面
    path: "/",
    name: "vue",
    redirect: "/web-view",
    meta: { auth: true },
  },
  {
    path: "/web-view",
    name: "web-view",
    component: () => import("@/views/WebView.vue"),
  },
  {
    // 会议分享
    path: "/meeting-sharing",
    name: "meeting-sharing",
    component: () => import("@/views/Home.vue"),
  },
  // {
  //   // 活动详情
  //   path: "/goActivity",
  //   name: "goActivity",
  //   component: () => import("@/views/GoActivity.vue"),
  // },
  {
    // 活动详情
    path: "/activity",
    name: "activity",
    component: () => import("@/views/Activity.vue"),
  },
  {
    path: "/video-view",
    name: "video-view",
    component: () => import("@/views/VideoView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
