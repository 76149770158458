import request from "@/utils/request";

// app-会议类型验证
export function verifyType(params) {
  return request({
    url: `/operation-meeting/meetingApply/verifyType/${params}`,
    method: "get",
  });
}
// app-用户验证-短信
export function verifyUser(params) {
  return request({
    url: `/operation-meeting/meetingApply/verifyUser`,
    method: "get",
    params,
  });
}
// app-用户验证
export function verifyUserData(data) {
  return request({
    url: `/operation-meeting/meetingApply/verifyUserData`,
    method: "post",
    data,
  });
}
// 会议详情
export function get(params) {
  return request({
    url: `/operation-meeting/meetingApply/get/${params}`,
    method: "get",
  });
}
// 签到
export function updateSign(data) {
  return request({
    url: `/jfast-operation-meeting/meetingApply/updateSign`,
    method: "post",
    data,
  });
}

// 活动详情
export function getActivity(params) {
  return request({
    url: `/operation-basic/activity/getInfo/${params}`,
    method: "get",
  });
}
// 活动报名
export function signSave(data) {
  return request({
    url: `/operation-basic/sign/save`,
    method: "post",
    data,
  });
}
// 取消报名
export function signRemove(data) {
  return request({
    url: `/operation-basic/sign/remove`,
    method: "post",
    data,
  });
}
// 活动签到
export function signUpdate(data) {
  return request({
    url: `/operation-basic/sign/update`,
    method: "post",
    data,
  });
}
// 收藏
export function saveCollect(data) {
  return request({
    url: `/operation-basic/collect/save`,
    method: "post",
    data,
  });
}
// 取消收藏
export function removeCollect(data) {
  return request({
    url: `/operation-basic/collect/remove`,
    method: "post",
    data,
  });
}
